exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sermons-[id]-js": () => import("./../../../src/pages/sermons/[id].js" /* webpackChunkName: "component---src-pages-sermons-[id]-js" */),
  "component---src-pages-sermons-index-js": () => import("./../../../src/pages/sermons/index.js" /* webpackChunkName: "component---src-pages-sermons-index-js" */),
  "component---src-pages-sermons-sermon-body-js": () => import("./../../../src/pages/sermons/SermonBody.js" /* webpackChunkName: "component---src-pages-sermons-sermon-body-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

